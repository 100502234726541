import PropTypes from 'prop-types';
// @mui
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
// hooks
import { useResponsive } from '../../../hooks/use-responsive';
// utils
import { fDate, fDateTime } from '../../../utils/formatTime';
// // assets
// import { AvatarShape } from '../../../assets/illustrations';
// components
import Iconify from '../../../components/iconify';
import TextMaxLine from '../../../components/text-max-line';
import Label from '../../../components/label';

PostItem.propTypes = {
  post: PropTypes.object,
};

export default function PostItem({ post }) {
  // const mdUp = useResponsive('up', 'md');

  const { summary, description, start, end } = post;

  return (
    <Card>
      <PostContent
        title={summary}
        description={description}
        checkIn={fDateTime(start)}
        checkOut={fDateTime(end?.dateTime)}
        bookingDate={`${fDateTime(start)} - ${fDateTime(end?.dateTime)}`}
      />
    </Card>
  );
}

export function PostContent({ title, description, checkIn, checkOut, createdAt, index }) {
  const mdUp = useResponsive('up', 'md');

  const latestPostLarge = index === 0;

  const latestPostSmall = index === 1 || index === 2;

  return (
    <CardContent
      sx={{
        pt: 6,
        width: 1,
        ...((latestPostLarge || latestPostSmall) && {
          pt: 0,
          zIndex: 9,
          bottom: 0,
          position: 'absolute',
          color: 'common.white',
        }),
      }}
    >
      <Typography
        variant="caption"
        component="div"
        sx={{
          mb: 1,
          color: 'text.disabled',
          ...((latestPostLarge || latestPostSmall) && {
            opacity: 0.64,
            color: 'common.white',
          }),
        }}
      >
        {fDate(createdAt)}
      </Typography>

      <Label
        variant="filled"
        color="success"
        sx={{
          zIndex: 9,
          top: 16,
          right: 16,
          position: 'absolute',
          textTransform: 'uppercase',
        }}
      >
        {title?.match('Day') ? 'Day' : title?.match('Night') ? 'Night' : '21 Hrs'}
      </Label>

      <TextMaxLine color="success.light" variant={mdUp && latestPostLarge ? 'h5' : 'subtitle1'} line={2} persistent>
        {title}
      </TextMaxLine>

      <Stack
        spacing={1.5}
        sx={{
          mt: 3,
          typography: 'caption',
          fontSize: 14,
          color: 'text.disabled',
          ...((latestPostLarge || latestPostSmall) && {
            opacity: 0.64,
            color: 'common.white',
          }),
        }}
      >
        <Stack direction="row" alignItems="center">
          <Iconify icon="mdi:clock-time-five" width={20} sx={{ mr: 0.5, color: 'success.light' }} />
          {`Check-in: ${checkIn}`}
        </Stack>

        <Stack direction="row" alignItems="center">
          <Iconify icon="mdi:clock-time-nine" width={20} sx={{ mr: 0.5, color: 'error.light' }} />
          {`Check-out: ${checkOut}`}
        </Stack>

        <Stack direction="row" alignItems="center">
          <Iconify icon="gridicons:multiple-users" width={20} sx={{ mr: 0.5, color: 'info.light' }} />
          {description?.split('\n')[0]}
        </Stack>
      </Stack>
    </CardContent>
  );
}

PostContent.propTypes = {
  createdAt: PropTypes.string,
  index: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  checkIn: PropTypes.string,
  checkOut: PropTypes.string,
};
