import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import { useState } from 'react';
// @mui
// import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// components
import { useSettingsContext } from '../../../../components/settings';
//
import PostList from '../post-list';

BookingListHomeView.propTypes = {
  bookings: PropTypes.array,
};

export default function BookingListHomeView({ bookings }) {
  const settings = useSettingsContext();

  const [sortBy] = useState('latest');

  const { postsLoading } = [];

  const dataFiltered = applyFilter({
    inputData: bookings,
    sortBy,
  });

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <Typography
        variant="h4"
        sx={{
          my: { xs: 3, md: 5 },
        }}
      >
        The Backyard Glamp Bookings
      </Typography>

      <PostList posts={dataFiltered} loading={postsLoading} />
    </Container>
  );
}

const applyFilter = ({ inputData, sortBy }) => {
  if (sortBy === 'oldest') {
    return orderBy(inputData, ['start'], ['asc']);
  }

  return inputData;
};
