import { format, getTime, formatDistanceToNow, add } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTimeH(date) {
  try {
    return format(new Date(date), 'dd MMM yyyy HH:mm');
  } catch (error) {
    console.log(new Date(date));
    console.log(error);
    return '';
  }
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function fAddMonths(date, months) {
  return add(new Date(date), {
    years: 0,
    months,
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
}
