import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// api
import { getEvents } from '../api/getEvents';
// sections
import { BookingListHomeView } from '../sections/@dashboard/bookings/view';
// components
import LoadingScreen from '../components/LoadingScreen';

const calendarIDFb = process.env.REACT_APP_CALENDAR_ID_FB;

export default function BookingsPage() {
  const [bookingsFb, setBookingsFb] = useState([]);

  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    getEvents(calendarIDFb, setBookingsFb);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarIDFb]);

  useEffect(() => {
    setBookings(bookingsFb);
  }, [bookingsFb]);

  if (bookingsFb?.length === 0) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Helmet>
        <title> Dashboard: Bookings</title>
      </Helmet>

      <BookingListHomeView bookings={bookings} />
    </>
  );
}
