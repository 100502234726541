import { Navigate, useRoutes } from 'react-router-dom';
// layouts

import SimpleLayout from './layouts/simple';
//

import BookingsPage from './pages/BookingsPage';
import Page404 from './pages/Page404';
import Calendar from './pages/Calendar';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/bookings" />, index: true },
        { path: 'bookings', element: <BookingsPage /> },
      ],
    },

    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/calendar" />, index: true },
        { path: 'calendar', element: <Calendar /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
