import FullCalendar from '@fullcalendar/react'; // => request placed at the top
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import interactionPlugin from '@fullcalendar/interaction';
import { Helmet } from 'react-helmet-async';
import { useState, useRef, useEffect } from 'react';
// @mui
import { Card, Stack, Container, Typography } from '@mui/material';
// sections
import { CalendarStyle, CalendarToolbar } from '../sections/@dashboard/calendar';
// components
import LoadingScreen from '../components/LoadingScreen';
// api
import { getEvents } from '../api/getEvents';

const calendarIDFb = process.env.REACT_APP_CALENDAR_ID_FB;
const calendarIDM = process.env.REACT_APP_CALENDAR_ID_MAINTENANCE;

export default function Calendar() {
  const calendarRef = useRef(null);

  const [date, setDate] = useState(new Date());

  const [bookingsFb, setBookingsFb] = useState([]);
  const [maintenance, setMaintenance] = useState([]);
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    getEvents(calendarIDFb, setBookingsFb);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarIDFb]);

  useEffect(() => {
    if (bookingsFb?.length > 0) getEvents(calendarIDM, setMaintenance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingsFb]);

  useEffect(() => {
    setBookings(bookingsFb.concat(maintenance));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maintenance]);

  if (bookings?.length === 0) {
    return <LoadingScreen />;
  }

  const handleClickDatePrev = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleClickDateNext = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  return (
    <>
      <Helmet>
        <title> Calendar | The Backyard Glamp </title>
      </Helmet>
      <Container sx={{ pb: 5 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom mt={5} maxWidth={'80%'}>
            The Backyard Glamp Availability Calendar
          </Typography>
        </Stack>

        <Card>
          <CalendarStyle>
            <CalendarToolbar date={date} onNextDate={handleClickDateNext} onPrevDate={handleClickDatePrev} />
            <FullCalendar
              weekends
              droppable
              events={bookings}
              ref={calendarRef}
              rerenderDelay={10}
              initialDate={date}
              dayMaxEventRows={2}
              eventDisplay="block"
              headerToolbar={false}
              allDayMaintainDuration
              eventResizableFromStart
              height={'auto'}
              plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin]}
            />
          </CalendarStyle>
        </Card>
      </Container>
    </>
  );
}
