import { gapi } from 'gapi-script';

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

export const getEvents = (calendarID, setBookings) => {
  function initiate() {
    gapi.client
      .init({
        apiKey,
      })
      .then(() =>
        gapi.client.request({
          path: `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events?timeMin=${new Date().toISOString()}`,
        })
      )
      .then(
        (response) => {
          const d = new Date();
          const bookings = response?.result?.items
            ?.filter((booking) => new Date(booking?.end?.dateTime || booking?.end?.date).getTime() >= d.getTime())
            .sort(
              (a, b) => new Date(a?.start?.dateTime || a?.start?.date) - new Date(b?.start?.dateTime || b?.start?.date)
            );

          setBookings(
            bookings?.map((booking) => ({
              ...booking,
              checkIn: new Date(booking?.start?.dateTime).getTime() || new Date(booking?.start?.date).getTime(),
              type: 'fb',
              title: booking?.summary.match('Night')
                ? `Night
                ${new Date(booking?.start?.dateTime)?.toLocaleString('en-US', {
                  hour: 'numeric',
                  hour12: true,
                })} - ${new Date(booking?.end?.dateTime)?.toLocaleString('en-US', {
                    hour: 'numeric',
                    hour12: true,
                  })}
              `
                : booking?.summary.match('Day')
                ? `Day
                ${new Date(booking?.start?.dateTime)?.toLocaleString('en-US', {
                  hour: 'numeric',
                  hour12: true,
                })} - ${new Date(booking?.end?.dateTime)?.toLocaleString('en-US', {
                    hour: 'numeric',
                    hour12: true,
                  })}
              `
                : `21 Hrs
                ${new Date(booking?.start?.dateTime)?.toLocaleString('en-US', {
                  hour: 'numeric',
                  hour12: true,
                })} - ${new Date(booking?.end?.dateTime)?.toLocaleString('en-US', {
                    hour: 'numeric',
                    hour12: true,
                  })}
              `,
              start: new Date(booking?.start?.dateTime).getTime() || new Date(booking?.start?.date).getTime(),
              // end: new Date(booking?.end?.dateTime).getTime() || new Date(booking?.end?.date).getTime(),
              allDay: true,
            }))
          );
        },
        (err) => [false, err]
      );
  }
  gapi.load('client', initiate);
};

export const getEventsAirbnb = (calendarID, setBookings) => {
  function initiate() {
    gapi.client
      .init({
        apiKey,
      })
      .then(() =>
        gapi.client.request({
          path: `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events?timeMax=${new Date()}`,
        })
      )
      .then(
        (response) => {
          const d = new Date();
          const bookings = response?.result?.items?.filter(
            (booking) =>
              new Date(booking?.end?.date).setHours(14, 0, 0, 0) >= d.getTime() &&
              booking?.summary !== 'Airbnb (Not available)'
          );

          setBookings(
            bookings?.map((booking) => ({
              ...booking,
              checkIn: new Date(booking?.start?.date).getTime(),
              type: 'airbnb',
            }))
          );
        },
        (err) => [false, err]
      );
  }
  gapi.load('client', initiate);
};
